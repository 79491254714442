import React from "react"
import Layout from "../components/layout";

export default function NotFound() {


    return (
    <Layout isPortfolio={false}>                

        <div style={{textAlign:'center'}}>
            Page was not found
        </div>
    </Layout>

    );
}